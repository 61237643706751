import React, { useState, useRef, useEffect } from "react";
//  import "./Landing.scss";
import scrollCircle from "../../assets/images/Ellipse 4.png";
import indilabslogolandingpage from "../../assets/images/logoindilabs.svg";
import calendarMonth from "../../assets/images/calendar_month.png";
import landingpagedtopimage from "../../assets/images/landingpagetopimagenew.webp";
import endtoendImage from "../../assets/images/endtoend.png";
import DynamicStrategiesImage from "../../assets/images/DynamicStrategies.png";
import ProfitOptimizationImage from "../../assets/images/ProfitOptimization.png";
import DecisionSciencePoweredImage from "../../assets/images/DecisionSciencePowered.png";
import EfficiencyEnhancementImage from "../../assets/images/EfficiencyEnhancement.png";
import YourSolutionsforporfitablegrowthImage from "../../assets/images/YourSolutionsforporfitablegrowth.png";
import indilabsImage from "../../assets/images/indliabs.png";
import LinkedinImage from "../../assets/images/Linkedin.png";
import MailImage from "../../assets/images/mail.png";
import CallImage from "../../assets/images/call.png";
import fpsimage from "../../assets/images/30fps.png";
import FrameI2 from "../../assets/images/FrameI2.svg";
import Frame1 from "../../assets/images/Frame1.svg";
import Frame3 from "../../assets/images/Frame3.svg";
import FrameI4 from "../../assets/images/FrameI4.svg";
import FrameImage from "../../assets/images/FrameimgLP.svg";
import separationline from "../../assets/images/separationline.svg";
import MissionImage from "../../assets/images/MissionImage.png";
import ValuesImage from "../../assets/images/ValuesImage.png";
import VisionImage from "../../assets/images/VisionImage.png";
import FounderImage from "../../assets/images/FounderImage.png";
import { Link, useNavigate } from "react-router-dom";
import { CarouselDefault } from "./Carousel";
import ConatactForm from "../Contact Form/ConatactForm";
import { CarouselAbout } from "./CarouselAbout";

const content = [
  {
    title: "WHY INDILABS.AI?",
    description:
      "Category defining AI & ML powered Credit Risk and Collections Strategy platform engineered for driving performance",
  },
];

const dataCards = [
  {
    src: Frame1,
    title: "Risk Monitoring & Diagnostics",
    description:
      "Always-on intelligence to mitigate Risks to credit performance across the cycle so you can focus on profitable growth.",
  },
  {
    src: FrameI2,
    title: "Pre-Delinquency Management",
    description:
      "Data driven applications that spot risks and enable targeted actions to mitigate flow to Collections.",
  },
  {
    src: Frame3,
    title: "Collection Strategy  Optimization",
    description:
      "Expert applications that drive Collections performance with AI/ ML models, testing and optimization.",
  },
  {
    src: FrameI4,
    title: "Recovery Performance",
    description:
      "Powerful applications that leverage data and AI/ML models to drive step change in cash flow performance for post write off accounts",
  },
];
const scrollRow = [
  {
    id: 0,
    rowText: "Reduce Man Hours",
    rowImage: scrollCircle,
  },
  {
    id: 1,
    rowText: "Speedy Process",
    rowImage: scrollCircle,
  },
  {
    id: 2,
    rowText: "Improved Strategies",
    rowImage: scrollCircle,
  },
  {
    id: 3,
    rowText: "Faster Recoveries",
    rowImage: scrollCircle,
  },
  {
    id: 4,
    rowText: "Reduce Man Hours",
    rowImage: scrollCircle,
  },
];

const jsonData = [
  {
    title: "Banks",
    countImage: fpsimage,

    description:
      "Drive sustainable growth and profitability with our tailored services.",
  },
  {
    title: "Financing Firms",
    countImage: fpsimage,
    description:
      "Recover outstanding debts efficiently with our AI/ML platform.",
  },
  {
    title: "Digital Lenders",
    countImage: fpsimage,
    description:
      "Optimize financial performance and mitigate risks with advanced capabilities.",
  },
];

const data = [
  {
    bgColor: "#F2EFFE",
    text: "OPTIMIZED",
    textColor: "#5C4E8E",
  },
  {
    bgColor: "#FFF0D9",
    text: "ADAPTIVE",
    textColor: "#AC2631",
  },
  {
    bgColor: "#E2F3FF",
    text: "SECURE",
    textColor: "#236695",
  },
  {
    bgColor: "#E0FFD6",
    text: "INTELLIGENT",
    textColor: "#2D8410",
  },
  {
    bgColor: "#DCD6FF",
    text: "SIMPLIFIED",
    textColor: "#2E2356",
  },
];
const aboutJsonData = [
  {
    cardImage: MissionImage,
    heading: "Mission",
    description:
      "Are you ready to optimize your Risk Intelligence and Collections capabilities for Profitable Growth?",
  },
  {
    cardImage: ValuesImage,
    heading: "Values",
    description:
      "Are you ready to optimize your Risk Intelligence and Collections capabilities for Profitable Growth?",
  },
  {
    cardImage: VisionImage,
    heading: "Vision",
    description:
      "Are you ready to optimize your Risk Intelligence and Collections capabilities for Profitable Growth?",
  },
];

const founderData = [
  {
    title: "MEET THE FOUNDER",
    name: "Amit Chandola",
    description1:
      "20+ years of global experience in Credit and collections across US, India, Hong Kong and the Asia Pacific markets",
    description2:
      "In his most recent role, Amit was Asia Head of Credit Control Services for a Global Bank, with scope spanning USD100+ Billion in consumer lending assets across 16 markets",
  },
];

const cardData = [
  {
    image: endtoendImage,
    title: "End-to-end risk mitigation",
    description:
      "Monitor and manage risk to performance across all stages of credit portfolio from Origination to Recoveries",
    width: "w-[434px]",
  },
  {
    image: DynamicStrategiesImage,
    title: "Dynamic Strategies",
    description:
      "Achieve profitable growth by leveraging AI/ML to test, learn and deploy strategies that adapt to changing risk and scale of the business.",
    width: "w-[770px]",
  },
  {
    image: ProfitOptimizationImage,
    title: "Profit Optimization",
    description:
      "Deploy strategies that seek to increase yield and add value to the bottomline at all stages in Credit cycle.",
    width: "w-[381px]",
  },
  {
    image: DecisionSciencePoweredImage,
    title: "Decision Science Powered",
    description:
      "Achieve high performance through data science driven strategies and automated actions that can be tested and improved",
    width: "w-[371px]",
  },
  {
    image: EfficiencyEnhancementImage,
    title: "Efficiency Enhancement",
    description:
      "Improve cost efficiencies by reducing manual interventions and leveraging AI/ML to optimize the best strategies",
    width: "w-[408px]",
  },
];

const getWidthClass = (width) => {
  switch (width) {
    case "w-[434px]":
      return "xl:w-[434px]";
    case "w-[770px]":
      return "xl:w-[770px]";
    case "w-[381px]":
      return "xl:w-[381px]";
    case "w-[371px]":
      return "xl:w-[371px]";
    case "w-[408px]":
      return "xl:w-[408px]";
    default:
      return "";
  }
};





const Landingpage = ({ showRef , homeRef}) => {
  useEffect(() => {
    if (showRef == true) {
      handleScrollToWhyindilabsai(WhyindilabsaiRef);
    }
    else if(homeRef==true){
      handleScrollToHome(HomeRef)
    }
  }, []);

  const [activePage, setActivePage] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const WhyindilabsaiRef = useRef(null);
  const HomeRef=useRef(null)
  const navigate = useNavigate();

  const handleProductClick = () => {
    //  setActivePage("");

    handleScrollToWhyindilabsai(WhyindilabsaiRef);
  };

  const handleAboutClick = () => {
    navigate("/about");
  };
  const handleAbout = () => {
    navigate("/about");
  };
  const handleScrollToHome = () => {
    HomeRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleScrollToWhyindilabsai = () => {
    WhyindilabsaiRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div className="overflow-hidden w-full h-fit overflow-x-auto "ref={HomeRef}>
        <div className="w-full">
          <div className="flex sm:justify-around items-center p-3 px-2 sm:px-16">
            <div className="flex items-center cursor-pointer">
              <a href={"/"}><img src={indilabslogolandingpage} alt="logo" className="" /></a>
            </div>
            <div className="flex gap-2 sm:gap-16 w-full items-center justify-center">
              <div className="text-[#000000]  font-[400] sm:font-[500] text-[16px] font-['DM Sans']">
                <button onClick={handleProductClick}  style={{
                    borderBottom:
                      activePage === "" ? "2px solid #000000" : "none",
                  }}>Product</button>
              </div>
              <div className="text-[#000000] font-[400] sm:font-[500] text-[16px] font-['DM Sans']">
                <button
                  onClick={handleAboutClick}
                  style={{
                    borderBottom:
                      activePage === "About" ? "2px solid #000000" : "none",
                  }}
                >
                  {" "}
                  About
                </button>
              </div>
            </div>
            <div
              className="hidden sm:flex bg-[#E84855] items-center gap-2 px-1 sm:px-4 py-2 rounded-3xl cursor-pointer z-40"
              onClick={openModal}
            >
              <div className="text-[#FFFFFF] flex self-start font-[500] w-[100px] text-[16px] font-['DM Sans']">
                Book a Demo
              </div>
              <div className="w-[20px] h-[20px]">
                <img src={calendarMonth} alt="calendaricon" className="" />
              </div>
            </div>
            <div
              className="flex sm:hidden fixed bottom-10 right-0 bg-[#E84855] items-center gap-2 px-4 py-2 rounded-3xl z-50"
              onClick={openModal}
            >
              <div className="text-[#FFFFFF] flex self-start font-[500] text-[16px] font-['DM Sans']">
                Book a Demo
              </div>
              <div className="w-[20px] h-[20px]">
                <img src={calendarMonth} alt="calendaricon" className="" />
              </div>
            </div>
          </div>
        </div>
        {activePage === "" && (
          <>
            <div className=" w-full bg-[#FFEEEE]  bg-gradient-to-b from-[#FFEEEE] to-[#FFFFFF]">
              <div className="flex justify-between flex-wrap lg:flex-nowrap items-center xl:items-center lg:flex lg:flex-row lg:items-center  md:flex-col md:items-center sm:flex-col sm:items-center  px-16 py-16 xl:px-32 lg:px-22 ml-0 sm:ml-0 lg:-ml-16 xl:ml-0 md:px-18 sm:px-3 lg:pl-12">
                <div className="flex flex-col gap-2 justify-center items-center xl:items-start lg:items-start md:items-center sm:items-center ">
                  <div className="text-[#000000] font-[600] font-[Open Sans] text-center xl:text-start lg:text-start md:text-center sm:text-center text-[22px] xl:text-[52px] lg:text-[52px] md:text-[42px] sm:text-[22px]  px-1 xl:px-16 lg:px-16 md:px-14 sm:px-0">
                    The Business of Lending is Transforming
                  </div>
                  <div className="text-[#535353] font-[300] text-center xl:text-start lg:text-start md:text-center sm:text-center text-[12px] xl:text-[24px] lg:text-[24px] md:text-[18px] sm:text-[12px] font-['DM Sans'] tracking-tight px-1 xl:px-16 lg:px-16 md:px-14 sm:px-0">
                    Are your Risk Intelligence and Collection Capabilities
                    optimized for{" "}
                    <span className="text-[#AC2631] font-['DM Sans'] font-[600] italic ">
                      {" "}
                      Profitable Growth?
                    </span>
                  </div>
                  <div
                    className="w-[144px] flex items-center xl:items-center lg:item-center  md:items-center sm:items-center px-6 py-2 rounded-3xl text-[#FFFFFF] mt-6 ml-0 xl:mt-16 xl:ml-16 lg:mt-16 lg:ml-16 md:mt-12 md:ml-12 sm:mt-6 sm:ml-0 cursor-pointer"
                    style={{ background: "#E84855" }} onClick={handleScrollToWhyindilabsai}
                  >
                    Explore More
                  </div>
                </div>
                <div className="flex justify-center w-full xl:w-[640px] lg:w-[550px] sm:w-[600px] ml-4  lg:-ml-4 lg:mr-8 xl:ml-6 mt-10 lg:mt-0 md:ml-6 sm:ml-4 py-2">
                  <div className="w-full h-full xl:w-[640px] lg:w-[550px] md:w-[600px] sm:w-full rounded-[16px] h xl:h-[490px] lg:h-[490px] md:h-[490px] sm:h-[490px] z-1">
                    <div className="z-0">
                      <img
                        src={landingpagedtopimage}
                        alt="dashboardimage"
                        // style={{ height: "490px", borderRadius: "16px" }}
                        className="w-full h-full xl:w-[640px] lg:w-[550px] xl:h-[490px] lg:h-[450px] md:w-[600px] md:h-[490px] sm:w-full sm:h-[490px] rounded-2xl"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-center items-center -mt-4 pb-4">
                <img src={separationline} alt="separation" />
              </div>

              <section ref={WhyindilabsaiRef} className="relative">
                <div className="flex flex-col items-center">
                  <div className="w-[300px]  sm:w-[510px] md:w-[520px] lg:w-[900px] xl:w-[1048px] 2xl:w-[1348px] h-[350px]  sm:h-[340px] md:h-[320px] lg:h-[385px] bg-[#E84855] rounded-r-[20px] rounded-l-[20px] flex flex-col items-center">
                    {content.map((item, index) => (
                      <div
                        key={index}
                        className="text-center flex flex-col items-center pt-4  sm:pt-8 md:pt-8 lg:pt-16"
                      >
                        <div className=" p-1  sm:p-2 md:p-3  mb-2 sm:mb-3 md:mb-1 lg:mb-4 w-[250px] sm:w-[300px]  rounded-[40px] bg-white text-[#AC2631] font-['DM Sans'] font-[600] text-[20px] flex items-center justify-center">
                          {item.title}
                        </div>
                        <div className="w-[290] sm:w-[480px]  md:w-[500px] lg:w-[800px] h-[150px] sm:h-[95px]  md:h-[80px] lg:h-[90px] rounded-[40px] text-[#FFFFFF] font-['DM Sans'] font-[600] text-[20px]">
                          {item.description}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className=" p-4 absolute top-[65%] hidden lg:flex w-full gap-4 xl:gap-6 2xl:gap-12 justify-center overflow-hidden ">
                    {dataCards.map((item, index) => (
                      <div
                        key={index}
                        className="h-full lg:h-[340px] xl:h-[340px]  2xl:h-[340px]  w-full max-w-[300px] xl:max-w-[300px] 2xl:max-w-[348px] bg-white rounded-[10px] overflow-hidden flex flex-col items-center shadow-lg"
                      >
                        <div className="w-full   overflow-hidden     bg-gradient-to-b from-[#AC2631] to-[#FFFFFF] ">
                          <img
                            src={item.src}
                            alt={item.title}
                            className="w-full h-full object-cover  z-100"
                          />
                        </div>
                        <h2
                          className="w-full text-center font-['DM Sans'] font-[600] text-[20px] p-2 border-l border-r text-[#000000]"
                          // dangerouslySetInnerHTML={{ __html: item.title }}
                        >{item.title}</h2>
                        <p
                          className="w-full flex-grow p-2 border-l border-r border-b font-['DM Sans'] font-[300] text-[14px] text-[#000000] text-center leading-[24px] tracking-wide"
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        ></p>
                      </div>
                    ))}
                  </div>

                  <div className=" p-4 absolute top-[65%] flex sm:hidden object-cover justify-center items-center">
                    <div className="w-[330px] h-[360px] rounded-[10px] shadow-lg">
                      <CarouselDefault dataCards={dataCards} numPage={1} />
                    </div>
                  </div>
                  <div className=" p-4 absolute top-[65%] hidden sm:flex lg:hidden object-cover justify-center items-center">
                    <div className="w-[640px] h-[360px]  rounded-[10px] shadow-lg">
                      <CarouselDefault dataCards={dataCards} numPage={2} />
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="flex relative flex-col items-center  gap-4 mt-96 lg:mt-80">
              <button className="bg-[#E84855] text-[#FFFFFF] font-['DM Sans'] font-[500] py-3 px-9 rounded-[100px]"onClick={openModal}>
                Get in Touch
              </button>
              <img src={FrameImage} alt="" className=" h-full max-h-[500px] w-full max-w-[1200px] p-5" />
            </div>

            <div
              className=" w-[100%] bg-[#FBFBFB] h-[100%] flex flex-col p-4 items-center bg-cover bg-center"
              style={{
                backgroundImage: `url(${YourSolutionsforporfitablegrowthImage})`,
              }}
            >
              <h1 className="font-['DM Sans'] font-[700] text-[32px] text-[#AC2631] text-center mt-9">
                Active Risk Management for Profitable Growth
              </h1>
              <p className="font-['DM Sans'] w-full lg:w-[950px] md:w-[600px] sm:w-[450px] font-[400] text-[18px] text-[#888686] text-center mt-3">
              Our unique platform is developed by Risk and Collections experts with decades of experience in managing Lending products across global markets. It brings together global best practices, tools and expertise in one end to end solution. 
              </p>
              <div className="flex flex-col flex-wrap justify-between lg:flex lg:flex-row lg:justify-center  md:flex-wrap md:justify-start sm:flex-col gap-12 lg:gap-12 md:gap-6 sm:gap-4 w-full xl:w-[1200px] lg:w-[1020px]  md:w-[800px] sm:w-[400px] mt-20">
                {data.map((each, index) => (
                  <div
                    key={index}
                    className="flex flex-col gap-3 lg:flex-row lg:gap-3 md:gap-3 sm:gap-2 sm:w-full lg:w-auto md:w-auto items-center "
                  >
                    <div
                      className="rounded-3xl w-[190px] text-center p-2"
                      style={{ backgroundColor: each.bgColor }}
                    >
                      <div
                        className="text-['DM Sans'] font-[700] text-[17px]"
                        style={{ color: each.textColor }}
                      >
                        {each.text}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="gap-11 flex flex-wrap justify-center items-center w-full  max-w-[1500px] mb-20 mt-20">
                {cardData.map((card, index) => (
                  <CardComponent key={index} card={card} />
                ))}
              </div>
            </div>

            <div className="w-full items-center flex flex-col justify-center h-full mt-10 px-32 xl:px-32 lg:px-32 md:px-32 max-sm:px-12 sm:px-12">
              <div className="bg-[#E84855] min-w-[300px] w-full xl:w-full lg:w-full md:w-full sm:w-full h-full flex flex-col items-center rounded-xl p-2 lg:p-12 md:p-20 sm:p-2 ">
                <div className="flex flex-col  items-center">
                  <p className="w-full font-['DM Sans'] font-[700] text-[18px] lg:text-[32px] md:text-[28px] sm:text-[18px] text-[#ffffff] text-center sm:px-3">
                    Built for lenders, big or small
                  </p>

                  <div className="px-22 flex flex-col 2xl:flex-row justify-between items-center 2xl:justify-between lg:items-center md:flex md:flex-col md:justify-between md:items-center sm:flex sm:flex-col sm:justify-between sm:items-center gap-6 xl:gap-10 lg:gap-8 md:gap-6 sm:gap-6  mt-10">
                    {jsonData.map((each, index) => (
                      <div
                        key={index}
                        className="w-full h-full 2xl:w-full xl:h-[153px] lg:w-[383px] lg:h-[153px] md:w-[383px] md:h-[153px] sm:w-[383px] sm:h-full  rounded-xl gap-5 py-4 px-5 xl:px-5 lg:px-5 md:px-5 sm:px-5 flex flex-wrap items-center sm:mt-2 lg:mt-0 md:mt-0 mt-2"
                      >
                        <div className="bg-[#FFFFFF] h-[29px] w-[29px] lg:w-[49px] lg:h-[49px] md:w-[49px] md:h-[49px] sm:w-[29px] sm:h-[29px]  rounded-full flex items-center justify-center">
                          <img src={each.countImage} alt="fpsicon" />
                        </div>
                        <h2 className="font-['DM Sans'] font-[700] text-[#ffffff] text-[18px] lg:text-[22px] md:text-[22px] sm:text-[18px]  ">
                          {each.title}
                        </h2>
                        <p className="font-['DM Sans'] font-[400] text-[#ffffff] text-[14px] lg:text-[18px] md:text-[18px] sm:text-[12px]  tracking-wide">
                          {each.description}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="flex flex-col items-center gap-4 mt-16 mb-16">
          <div className="font-['DM Sans'] font-[700] text-[36px] text-[#000000] text-center">
            Ready to Grow Profits with Indilabs.ai?
          </div>
          <div className="font-['DM Sans'] font-[400] text-[18px] text-[#000000] text-center min-w-full lg:w-[664px]">
          Talk to us today to learn more or to test our solution.
          </div>
          <button className="bg-[#E84855] text-[#FFFFFF] font-['DM Sans'] font-[500] py-3 px-6 rounded-[100px] mt-8" onClick={openModal}>
            Get in Touch
          </button>
        </div>

        <div className="p-6 sm:p-[3rem] flex flex-col gap-4 bg-[#FFEEEE]">
          <footer className="flex flex-col sm:flex-row justify-between items-center sm:items-start gap-4 mb-8 text-center sm:text-left">
            <div className="w-full sm:w-1/5 flex justify-center sm:justify-start cursor-pointer"onClick={handleScrollToHome}>
              <img src={indilabsImage} alt="" />
            </div>
            <div className="w-full sm:w-1/5">
              <div className="flex flex-col gap-4">
                <p className="text-[#AC2631] text-[22px] sm:text-[24px] font-[700] font-['DM Sans'] leading-6">
                  Explore
                </p>
                <p className="font-['DM Sans'] font-[400] text-[16px] sm:text-[18px] text-[#000000] leading-5">
                  <button onClick={handleScrollToWhyindilabsai}>Product</button>
                </p>
                {/* <p className="font-['DM Sans'] font-[400] cursor-pointer text-[16px] sm:text-[18px] text-[#000000] leading-5">
                  Why Indilabs.ai
                </p> */}
              </div>
            </div>
            <div className="w-full sm:w-1/5">
              <div className="flex flex-col gap-4">
                <p className="text-[#AC2631] text-[22px] sm:text-[24px] font-[700] font-['DM Sans'] leading-6">
                  Company
                </p>
                <p className="font-['DM Sans'] cursor-pointer font-[400] text-[16px] sm:text-[18px] text-[#000000] leading-5">
                  <Link to={"/about"}>About us</Link>
                </p>
                {/* <p className="font-['DM Sans'] cursor-pointer font-[400] text-[16px] sm:text-[18px] text-[#000000] leading-5">
                  Features
                </p> */}
                <p className="font-['DM Sans'] font-[400] cursor-pointer text-[16px] sm:text-[18px] text-[#000000] leading-5">
                  Privacy Policy
                </p>
              </div>
            </div>
            <div className="w-full sm:w-[30%] ">
              <div className="flex flex-col gap-4">
                <p className="text-[#AC2631] text-[22px] sm:text-[24px] font-[700] font-['DM Sans'] leading-6">
                  Get in touch
                </p>
                <p className="font-['DM Sans'] font-[400] text-[16px] sm:text-[18px] text-[#000000] leading-5">
                  Reach out to us for any assistance and support
                </p>
                <div className="flex flex-col items-center cursor-pointer sm:flex-row sm:justify-start gap-4">
                  <div className="flex items-center justify-center gap-2">
                    <img
                      src={MailImage}
                      alt=""
                      className="w-[15px] h-[12px] sm:w-[18px] sm:h-[15px]"
                    />
                    <p className="font-['DM Sans'] font-[400] text-[16px] sm:text-[18px] text-[#000000] leading-5">
                      info@indilabs.ai
                    </p>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row items-center sm:items-start cursor-pointer justify-center sm:justify-start gap-4">
                  <div className="flex items-center justify-center  gap-2">
                    <img
                      src={CallImage}
                      alt=""
                      className="w-[15px] h-[12px] sm:w-[18px] sm:h-[15px]"
                    />
                    <p className="font-['DM Sans'] font-[400] text-[16px] sm:text-[18px] text-[#000000] leading-5">
                      +919618111778
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/5 flex justify-center cursor-pointer sm:justify-end">
             <a href={" https://www.linkedin.com/company/indilabs-ai/"}> <img src={LinkedinImage} alt="" /></a>
            </div>
          </footer>
          <div className="flex w-full justify-center flex-col items-center relative top-12">
            <div className="bg-[#E1E1E1] w-full sm:w-[90%] text-center border"></div>
            <div className="text-center text-[#B5B5B5] font-['DM Sans'] text-[14px] sm:text-[16px] font-[400] leading-4 tracking-wide mt-3 sm:mt-5 mb-3 sm:mb-5">
              Copyright @2024 Company All Rights Reserved
            </div>
          </div>
        </div>

        {modalIsOpen ? (
          <ConatactForm
            modalIsOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            closeModal={closeModal}
            openModal={openModal}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

const CardComponent = ({ card }) => (
  <div
    className={`h-[251px] bg-white w-full sm:w-[450px] md:w-[650px] lg:w-[800px] ${getWidthClass(
      card.width
    )} gap-6 p-6 rounded-lg shadow-md shadow-[#e8485540] flex flex-col justify-center items-start`}
  >
    <img src={card.image} alt={card.title} className="w-[52px] h-[52px]" />
    <div className="gap-3 lg:gap-3 md:gap-2 sm:gap-1">
      <h2 className="font-['DM Sans'] font-[700] lg:text-[24px] md:text-[18px] sm:text-[14px] text-[#000000]">
        {card.title}
      </h2>
      <p className="text-[#535353] font-['DM Sans'] font-[400] text-[16px] leading-[28px] tracking-[0.69px]">
        {card.description}
      </p>
    </div>
  </div>
);

export default Landingpage;
