// import React from 'react'
import React, { useState, useRef, useEffect } from "react";
// import "./LandingPage.scss";
import scrollCircle from "../../assets/images/Ellipse 4.png";
import indilabslogolandingpage from "../../assets/images/logoindilabs.svg";
import calendarMonth from "../../assets/images/calendar_month.png";
import landingpagedtopimage from "../../assets/images/landingpagetopimagenew.jpg";
import endtoendImage from "../../assets/images/endtoend.png";
import DynamicStrategiesImage from "../../assets/images/DynamicStrategies.png";
import ProfitOptimizationImage from "../../assets/images/ProfitOptimization.png";
import DecisionSciencePoweredImage from "../../assets/images/DecisionSciencePowered.png";
import EfficiencyEnhancementImage from "../../assets/images/EfficiencyEnhancement.png";
import YourSolutionsforporfitablegrowthImage from "../../assets/images/YourSolutionsforporfitablegrowth.png";
import indilabsImage from "../../assets/images/indliabs.png";
import LinkedinImage from "../../assets/images/Linkedin.png";
import MailImage from "../../assets/images/mail.png";
import CallImage from "../../assets/images/call.png";
import fpsimage from "../../assets/images/30fps.png";
import FrameI2 from "../../assets/images/FrameI2.svg";
import Frame1 from "../../assets/images/Frame1.jpg";
import Frame3 from "../../assets/images/Frame3.jpg";
import FrameI4 from "../../assets/images/FrameI4.svg";
import FrameImage from "../../assets/images/FrameImage.svg";
import separationline from "../../assets/images/separationline.svg";
import MissionImage from "../../assets/images/MissionImage.png";
import ValuesImage from "../../assets/images/ValuesImage.png";
import VisionImage from "../../assets/images/VisionImage.png";
import FounderImage from "../../assets/images/FounderImage.png";
import { useNavigate } from "react-router-dom";
import { CarouselDefault } from "./Carousel";
import ConatactForm from "../Contact Form/ConatactForm";
import { CarouselAbout } from "./CarouselAbout";

const content = [
  {
    title: "WHY INDILABS.AI?",
    description:
      "Category defining AI & ML powered Credit Risk and Collections Strategy platform engineered for modern lending ecosystem",
  },
];

const dataCards = [
  {
    src: Frame1,
    title: "Portfolio Risk Monitoring & Diagnostics",
    description:
      "Are you ready to optimize your Risk<br />Intelligence and Collections<br />capabilities for Profitable Growth?",
  },
  {
    src: FrameI2,
    title: "Pre-Delinquency Management",
    description:
      "Are you ready to optimize your Risk<br />Intelligence and Collections<br />capabilities for Profitable Growth?",
  },
  {
    src: Frame3,
    title: "Collection Strategy <br /> Optimization",
    description:
      "Are you ready to optimize your Risk<br />Intelligence and Collections<br />capabilities for Profitable Growth?",
  },
  {
    src: FrameI4,
    title: "Recovery Optimization",
    description:
      "Are you ready to optimize your Risk<br />Intelligence and Collections<br />capabilities for Profitable Growth?",
  },
];
const scrollRow = [
  {
    id: 0,
    rowText: "Reduce Man Hours",
    rowImage: scrollCircle,
  },
  {
    id: 1,
    rowText: "Speedy Process",
    rowImage: scrollCircle,
  },
  {
    id: 2,
    rowText: "Improved Strategies",
    rowImage: scrollCircle,
  },
  {
    id: 3,
    rowText: "Faster Recoveries",
    rowImage: scrollCircle,
  },
  {
    id: 4,
    rowText: "Reduce Man Hours",
    rowImage: scrollCircle,
  },
];

const jsonData = [
  {
    title: "Banks",
    countImage: fpsimage,

    description:
      "Drive sustainable growth and profitability with our tailored services.",
  },
  {
    title: "Financing Firms",
    countImage: fpsimage,
    description:
      "Recover outstanding debts efficiently with our AI/ML platform.",
  },
  {
    title: "Digital Lenders",
    countImage: fpsimage,
    description:
      "Optimize financial performance and mitigate risks with advanced capabilities.",
  },
];

const data = [
  {
    bgColor: "#F2EFFE",
    text: "OPTIMIZED",
    textColor: "#5C4E8E",
  },
  {
    bgColor: "#FFF0D9",
    text: "ADAPTIVE",
    textColor: "#AC2631",
  },
  {
    bgColor: "#E2F3FF",
    text: "SECURE",
    textColor: "#236695",
  },
  {
    bgColor: "#E0FFD6",
    text: "INTELLIGENT",
    textColor: "#2D8410",
  },
  {
    bgColor: "#DCD6FF",
    text: "SIMPLIFIED",
    textColor: "#2E2356",
  },
];
const aboutJsonData = [
  {
    cardImage: MissionImage,
    heading: "Global Capabilities",
    description:
      "Our team of experienced world class professionals brings best global practices, business knowhow and proven ideas at your service, right from the start.",
  },
  {
    cardImage: ValuesImage,
    heading: "Performance Focused",
    description:
      "We are rooted in expertise and committed to delivering value to your business through strategies that adapt flexibly to your business model.",
  },
  {
    cardImage: VisionImage,
    heading: "Experiment Based",
    description:
      "Our solutions are engineered to continuously test and learn strategies and scale the winning ideas that enable profitable, long term growth for your business.",
  },
];

const founderData = [
  {
    title: "MEET THE FOUNDER",
    name: "Amit Chandola",
    description1:
      "20+ years of global experience in Credit and collections across US, India, Hong Kong and the Asia Pacific markets",
    description2:
      "In his most recent role, Amit was Asia Head of Credit Control Services for a Global Bank, with scope spanning USD100+ Billion in consumer lending assets across 16 markets",
  },
];

const cardData = [
  {
    image: endtoendImage,
    title: "End-to-end risk mitigation",
    description:
      "Seamless risk management through comprehensive strategies and leveraging AI/ML to drive optimal decisions at the right time.",
    width: "w-[434px]",
  },
  {
    image: DynamicStrategiesImage,
    title: "Dynamic Strategies",
    description:
      "Algorithms to tailor dynamic strategies that adapt to the unique risk characteristics of each client’s portfolio and customers.",
    width: "w-[770px]",
  },
  {
    image: ProfitOptimizationImage,
    title: "Profit Optimization",
    description:
      "From optimizing collection strategies to identifying revenue streams, we help you make smarter decisions that drive results.",
    width: "w-[351px]",
  },
  {
    image: DecisionSciencePoweredImage,
    title: "Decision Science Powered",
    description:
      "Are you ready to optimize your Risk Intelligence and Collections capabilities for Profitable Growth?",
    width: "w-[392px]",
  },
  {
    image: EfficiencyEnhancementImage,
    title: "Efficiency Enhancement",
    description:
      "Our AI/ML platform streamlines workflows, automates repetitive tasks, and empowers your team to focus on what matters most – growing your business.",
    width: "w-[417px]",
  },
];

const getWidthClass = (width) => {
  switch (width) {
    case "w-[434px]":
      return "lg:w-[434px]";
    case "w-[770px]":
      return "lg:w-[770px]";
    case "w-[351px]":
      return "lg:w-[351px]";
    case "w-[392px]":
      return "lg:w-[392px]";
    case "w-[417px]":
      return "lg:w-[417px]";
    default:
      return "";
  }
};

const About = () => {
  const [activePage, setActivePage] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const AboutRef = useRef(null);

  const navigate = useNavigate();

  const handleProductClick = () => {
    navigate("/");

    //   handleScrollToWhyindilabsai(WhyindilabsaiRef);
  };
  useEffect(() => {
    handleScrollToAbout(AboutRef);
  }, []);

  const handleScrollToAbout = () => {
    AboutRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  return (
    <div className="overflow-hidden w-full h-fit overflow-x-auto ">
      <div className="w-full" ref={AboutRef}>
        <div className="flex sm:justify-around items-center p-3 px-2 sm:px-16">
          <div className="flex items-center">
            <img src={indilabslogolandingpage} alt="logo" className="" />
          </div>
          <div className="flex gap-2 sm:gap-16 w-full items-center justify-center">
            <div className="text-[#000000]  font-[400] sm:font-[500] text-[16px] font-['DM Sans']">
              <button onClick={handleProductClick}>Product</button>
            </div>
            <div className="text-[#000000] font-[400]  sm:font-[500] text-[16px] font-['DM Sans']">
              <button className="border-[#000000] border-b-[2px]">About</button>
            </div>
          </div>
          <div
            className="hidden sm:flex bg-[#E84855] items-center gap-2 px-1 sm:px-4 py-2 rounded-3xl cursor-pointer z-40"
            onClick={openModal}
          >
            <div className="text-[#FFFFFF] flex self-start font-[500] w-[100px] text-[16px] font-['DM Sans']">
              Book a Demo
            </div>
            <div className="w-[20px] h-[20px]">
              <img src={calendarMonth} alt="calendaricon" className="" />
            </div>
          </div>
          <div
            className="flex sm:hidden fixed bottom-10 right-0 bg-[#E84855] items-center gap-2 px-4 py-2 rounded-3xl z-50"
            onClick={openModal}
          >
            <div className="text-[#FFFFFF] flex self-start font-[500] text-[16px] font-['DM Sans']">
              Book a Demo
            </div>
            <div className="w-[20px] h-[20px]">
              <img src={calendarMonth} alt="calendaricon" className="" />
            </div>
          </div>
        </div>
      </div>

      <>
        <div className="relative">
          <div
            className="w-full border-t-2 flex flex-col items-center gap-4 h-[970px] sm:h-[820px] md:h-[750px] lg:h-[720px] bg-cover bg-center mb-14"
            style={{
              backgroundImage: `url(${YourSolutionsforporfitablegrowthImage})`,
            }}
          >
            <p className="text-['DM Sans'] text-[#000000] font-[600] text-[32px] w-full  text-center mt-16">
              Fintech founded by experts in Risk management, Collections and
              AI/ML <br /> with decades of experience across Global markets.
            </p>
            <p className="text-['DM Sans'] text-[#000000] font-[400] text-[18px]  w-full  text-center mt-1 tracking-wide leading-[36px]">
              Our solutions harness the exponential power of Data, Cloud
              Computing and AI/ML to <br /> help lenders optimise risks and
              achieve profitable growth.
            </p>
            <button
              className="bg-[#E84855] text-[#FFFFFF] font-['DM Sans'] font-[500] py-3 px-9 rounded-[100px] mt-3 mb-3"
              onClick={openModal}
            >
              Get in Touch
            </button>
          </div>

          <div className="absolute top-[75%] inset-x-0 mx-auto hidden lg:flex gap-12 items-center w-full justify-center z-10 -mt-20  ">
            {aboutJsonData.map((each, index) => (
              <div
                key={index}
                className="bg-white h-[360px] xl:h-[325px] lg:w-[310px] xl:w-[380px] 2xl:w-[434px] rounded-[10px] gap-4 py-5 px-4 flex flex-col items-center justify-center shadow-[0_4px_6px_2px_#00000024]"
              >
                <img
                  src={each.cardImage}
                  alt={each.heading}
                  className="w-[48px] h-[48px] mt-4"
                />
                <p className="text-[#000000] font-['DM Sans'] font-[600] text-[24px] leading-8 text-center mt-2 mb-1">
                  {each.heading}
                </p>
                <p className="text-[#000000] font-['DM Sans'] font-[500] text-[16px] leading-[30px] tracking-wide w-[90%]  text-center">
                  {each.description}
                </p>
              </div>
            ))}
          </div>

          <div className="absolute top-[80%] inset-x-0 mx-auto flex sm:hidden gap-12  items-center w-full justify-center z-10 -mt-20 ">
            <div className="w-[310px] ">
              <CarouselAbout aboutJsonData={aboutJsonData} numPage={1} />
            </div>
          </div>

          <div className="absolute top-[75%] inset-x-0  mx-auto hidden sm:flex lg:hidden gap-12  items-center w-full z-10  -mt-20 tomediaMDWidth tomediaMD1Width object-cover justify-center">
            <div className=" sm:w-[640px] md:w-[768px] ">
              <CarouselAbout aboutJsonData={aboutJsonData} numPage={2} />
            </div>
          </div>
        </div>

        <div className="relative hidden xl:flex flex-col items-center bg-[#E84855] pt-40 z-0 -mt-36 w-full h-full xl:h-[702px] 2xl:h-[683px]">
          <div className="hidden xl:flex  xl:flex-row items-center gap-6 xl:gap-6 2xl:gap-6 xl:pr-10 2xl:pr-10  mt-10">
            <div className="flex ">
              <img
                src={FounderImage}
                alt="founder"
                className="max-w-xs md:max-w-sm lg:max-w-md rounded-lg xl:pr-10 "
              />
            </div>
            <div className="flex flex-col items-center gap-4 xl:gap-6 lg:gap-5 md:gap-5 xl:pl-20 2xl:pl-0 sm:gap-4">
              {founderData.map((founder, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center xl:items-start lg:items-center md:items-center sm:items-center max-w-2xl mx-auto p-4 mt-2 xl:mt-20 ml-0 xl:-ml-[5.5rem] 2xl:ml-0 lg:mt-0 md:mt-2 sm:mt-2"
                >
                  <h2 className="xl:text-center text-center w-[199px] h-[42px] font-['DM Sans'] font-[700] text-[14px] rounded-[40px] bg-[#FFEEEE] gap-[10px] text-[#000000] pt-3 mb-2">
                    {founder.title}
                  </h2>
                  <h3 className="xl:text-start text-center mb-1 xl:mb-4 lg:mb-2 md:mb-1 sm:mb-1 pb-1 xl:pb-10 lg:pb-5 md:pb-2 sm:pb-1  mt-1 xl:mt-6 lg:mt-4 md:mt-2 sm:mt-1 w-full h-[30px] xl:w-[288px] xl:h-[72px] lg:w-[288px] lg:h-[72px] md:w-[250px] md:h-[72px] sm:w-[180px] sm:h-[30px] font-['DM Sans'] font-[700] text-[20px] xl:text-[40px] lg:text-[40px] md:text-[30px] sm:text-[20px] text-[#FFFFFF]">
                    {founder.name}
                  </h3>
                  <p className="xl:text-start text-center mb-2 pt-2 w-full h-[40px] xl:w-[700px] xl:h-[100px] 2xl:w-[843px] 2xl:h-[80px]  lg:w-[600px] lg:h-[80px] md:w-[600px] md:h-[72px] sm:w-[400px] sm:h-[40px] font-['DM Sans'] font-[400] text-[12px] xl:text-[20px] lg:text-[20px] md:text-[18px] sm:text-[14px] gap-1 text-[#FFFFFF] tracking-wide leading-[36px]">
                    {founder.description1}
                  </p>
                  <p className="xl:text-start text-center pt-2 sm:mb-10 lg:mb-0 sm:pt-3 w-full h-[110px] xl:w-[700px] xl:h-[200px] 2xl:w-[843px] 2xl:h-[180px] lg:w-[600px] lg:h-[180px] md:w-[600px] md:h-[72px] sm:w-[400px] sm:h-[40px]  font-['DM Sans'] font-[400] text-[12px] xl:text-[20px] lg:text-[20px] md:text-[18px] sm:text-[14px] text-[#FFFFFF] tracking-wide leading-[36px]">
                    {founder.description2}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="relative flex flex-col xl:hidden items-center bg-[#E84855] pt-40 z-0 -mt-36 w-full h-full xl:h-[683px] lg:h-full md:h-full sm:h-full ">
          <div className="flex flex-col xl:hidden items-center gap-6 xl:gap-6 2xl:gap-6 xl:pr-30 2xl:pr-10 pr-0 mt-10">
            <div className="flex flex-col items-center gap-4 xl:gap-6 lg:gap-5 md:gap-5 sm:gap-4 mt-10">
              {founderData.map((founder, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center xl:items-start lg:items-center md:items-center sm:items-center max-w-2xl mx-auto p-4 mt-2 xl:mt-20 ml-0 xl:-ml-[5.5rem] 2xl:ml-0 lg:mt-0 md:mt-2 sm:mt-2"
                >
                  <h2 className="xl:text-center text-center w-[199px] h-[42px] font-['DM Sans'] font-[700] text-[14px] rounded-[40px] bg-[#FFEEEE] gap-[10px] text-[#000000] pt-3 mb-2">
                    {founder.title}
                  </h2>
                  <h3 className="xl:text-start text-center mb-1 xl:mb-4 lg:mb-2 md:mb-1 sm:mb-1 pb-1 xl:pb-10 lg:pb-5 md:pb-2 sm:pb-1  mt-1 xl:mt-6 lg:mt-4 md:mt-2 sm:mt-1 w-full h-[30px] xl:w-[288px] xl:h-[72px] lg:w-[288px] lg:h-[72px] md:w-[250px] md:h-[72px] sm:w-[180px] sm:h-[30px] font-['DM Sans'] font-[700] text-[20px] xl:text-[40px] lg:text-[40px] md:text-[30px] sm:text-[20px] text-[#FFFFFF]  ">
                    {founder.name}
                  </h3>
                  <p className="xl:text-start text-center mb-2 pt-2 w-full h-[40px] xl:w-[843px] xl:h-[80px] lg:w-[600px] lg:h-[80px] md:w-[600px] md:h-[72px] sm:w-[400px] sm:h-[40px] font-['DM Sans'] font-[400] text-[12px] xl:text-[20px] lg:text-[20px] md:text-[18px] sm:text-[14px] gap-1 text-[#FFFFFF] tracking-wide ">
                    {founder.description1}
                  </p>
                  <p className="xl:text-start text-center pt-2 sm:mb-10 lg:mb-0 sm:pt-3 w-full h-[110px] xl:w-[843px] xl:h-[180px] lg:w-[600px] lg:h-[180px] md:w-[600px] md:h-[72px] sm:w-[400px] sm:h-[40px]  font-['DM Sans'] font-[400] text-[12px] xl:text-[20px] lg:text-[20px] md:text-[18px] sm:text-[14px] text-[#FFFFFF]">
                    {founder.description2}
                  </p>
                </div>
              ))}
            </div>
            <div className="flex -mt-20">
              <img
                src={FounderImage}
                alt="founder"
                className="max-w-xs md:max-w-sm lg:max-w-md rounded-lg xl:pr-10 pr-0"
              />
            </div>
          </div>
        </div>
      </>
      <div className="flex flex-col items-center gap-4 mt-16 mb-16">
        <div className="font-['DM Sans'] font-[700] text-[36px] text-[#000000] text-center">
          Ready to Grow Profits with Indilabs.ai?
        </div>
        <div className="font-['DM Sans'] font-[400] text-[18px] text-[#000000] text-center min-w-full lg:w-[664px]">
          Talk to us today to learn more or to test our solution.
        </div>
        <button
          className="bg-[#E84855] text-[#FFFFFF] font-['DM Sans'] font-[500] py-3 px-9 rounded-[100px] mt-6"
          onClick={openModal}
        >
          Get in Touch
        </button>
      </div>

      <div className="p-6 sm:p-[3rem] flex flex-col gap-4 bg-[#FFEEEE]">
        <footer className="flex flex-col sm:flex-row justify-between items-center sm:items-start gap-4 mb-8 text-center sm:text-left">
          <div
            className="w-full sm:w-1/5 flex justify-center sm:justify-start cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img src={indilabsImage} alt="" />
          </div>
          <div className="w-full sm:w-1/5">
            <div className="flex flex-col gap-4">
              <p className="text-[#AC2631] text-[22px] sm:text-[24px] font-[700] font-['DM Sans'] leading-6">
                Explore
              </p>
              <p className="font-['DM Sans'] font-[400] text-[16px] sm:text-[18px] text-[#000000] leading-5">
                <button onClick={() => navigate("/")}>Product</button>
              </p>
              {/* <p className="font-['DM Sans'] font-[400] cursor-pointer text-[16px] sm:text-[18px] text-[#000000] leading-5">
                Why Indilabs.ai
              </p> */}
            </div>
          </div>
          <div className="w-full sm:w-1/5">
            <div className="flex flex-col gap-4">
              <p className="text-[#AC2631] text-[22px] sm:text-[24px] font-[700] font-['DM Sans'] leading-6">
                Company
              </p>
              <p className="font-['DM Sans'] cursor-pointer font-[400] text-[16px] sm:text-[18px] text-[#000000] leading-5">
                <button onClick={handleScrollToAbout}>About us</button>
              </p>
              {/* <p className="font-['DM Sans'] cursor-pointer font-[400] text-[16px] sm:text-[18px] text-[#000000] leading-5">
                Features
              </p> */}
              <p className="font-['DM Sans'] font-[400] cursor-pointer text-[16px] sm:text-[18px] text-[#000000] leading-5">
                Privacy Policy
              </p>
            </div>
          </div>
          <div className="w-full sm:w-[30%] ">
            <div className="flex flex-col gap-4">
              <p className="text-[#AC2631] text-[22px] sm:text-[24px] font-[700] font-['DM Sans'] leading-6">
                Get in touch
              </p>
              <p className="font-['DM Sans'] font-[400] text-[16px] sm:text-[18px] text-[#000000] leading-5">
                Reach out to us for any assistance and support
              </p>
              <div className="flex flex-col items-center cursor-pointer sm:flex-row sm:justify-start gap-4">
                <div className="flex items-center justify-center gap-2">
                  <img
                    src={MailImage}
                    alt=""
                    className="w-[15px] h-[12px] sm:w-[18px] sm:h-[15px]"
                  />
                  <p className="font-['DM Sans'] font-[400] text-[16px] sm:text-[18px] text-[#000000] leading-5">
                    info@indilabs.ai
                  </p>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row items-center sm:items-start cursor-pointer justify-center sm:justify-start gap-4">
                <div className="flex items-center justify-center  gap-2">
                  <img
                    src={CallImage}
                    alt=""
                    className="w-[15px] h-[12px] sm:w-[18px] sm:h-[15px]"
                  />
                  <p className="font-['DM Sans'] font-[400] text-[16px] sm:text-[18px] text-[#000000] leading-5">
                    +919618111778
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-1/5 flex justify-center cursor-pointer sm:justify-end">
            <a href={" https://www.linkedin.com/company/indilabs-ai/"}>
              {" "}
              <img src={LinkedinImage} alt="" />
            </a>
          </div>
        </footer>
        <div className="flex w-full justify-center flex-col items-center relative top-12">
          <div className="bg-[#E1E1E1] w-full sm:w-[90%] text-center border"></div>
          <div className="text-center text-[#B5B5B5] font-['DM Sans'] text-[14px] sm:text-[16px] font-[400] leading-4 tracking-wide mt-3 sm:mt-5 mb-3 sm:mb-5">
            Copyright @2024 Company All Rights Reserved
          </div>
        </div>
      </div>

      {modalIsOpen ? (
        <ConatactForm
          modalIsOpen={modalIsOpen}
          setIsOpen={setIsOpen}
          closeModal={closeModal}
          openModal={openModal}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default About;
