// import logo from './logo.svg';
import './index.css';
// import './App.css';
import { Route, Routes, useLocation } from "react-router-dom";
import LandingPage from "../src/pages/LandingPage/index";
import About from './pages/LandingPage/About';
import { useEffect, useState } from 'react';


function App() {
  const location = useLocation();
  const [showRef, setShowRef]=useState(false)
  const [homeRef, sethomeRef]=useState(false)
  useEffect(()=>{
    if(location.pathname==="/about"){
      setShowRef(true)
      sethomeRef(true)
    }
  },[location.pathname,showRef,homeRef])
  return (
      <Routes>
        <Route path="/" element={<LandingPage showRef={showRef} homeRef={homeRef} />} />
        <Route path="/about" element={< About showRef={showRef} homeRef={homeRef} />} />
        </Routes>
  );
}

export default App;